<template>
  <ion-page class="ion-page">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/checkedin-list" color="moto" text="" />
        </ion-buttons>
        <ion-button color="white" fill="clear" expand="full" @click="openSwitchPane" v-if="events && events.length && events.length > 1">
          {{ $t('switchevent') }} <ion-icon name="arrow-down" size="small" />
        </ion-button>
        <ion-title v-else>{{ $t('motoinsideappname') }}</ion-title>
      </ion-toolbar>
      <template v-if="isStreamApiSupported">
        <ion-toolbar v-if="!hasStartUpError">
          <ion-list>
          <ion-item><ion-label>Camera:</ion-label>
          <ion-select placeholder="Select camera" @ionChange="selectCamera($event.detail.value)" :value="selectedCamera">
            <ion-select-option value="auto">Auto</ion-select-option>
            <template v-for="(device, index) in devices">
              <ion-select-option :key="device.deviceId" :value="device.deviceId">{{ device.label || `Camera ${index + 1}` }}</ion-select-option>
            </template>
          </ion-select>
          </ion-item>
          </ion-list>
        </ion-toolbar>
        <ion-toolbar color="moto" class="small-toolbar">
          <ion-title size="small">
            {{ $t('checkinsonthisdevice') }}
            <span v-if="getCheckedInRiders">{{ getCheckedInRiders.length }}</span>
            <span v-else>0</span>
          </ion-title>
        </ion-toolbar>
      </template>
      <template v-else>
        <ion-toolbar color="danger" class="small-toolbar">
          <ion-title size="small">
            {{ $t('cameranotsupported') }}
          </ion-title>
        </ion-toolbar>
      </template>
      <template v-if="hasStartUpError">
        <ion-toolbar color="danger" class="small-toolbar">
          <ion-title size="small">
            {{ $t('camerapermissionswarning') }}
          </ion-title>
        </ion-toolbar>
      </template>
    </ion-header>

    <transition name="bounce">
      <div class="overlay-error" v-if="hasError"></div>
    </transition>
    <template v-if="isStreamApiSupported && !hasStartUpError">
      <qrcode-stream :camera="camera" @decode="displayContentsWeb" @init="onInit" />
    </template>

    <div class="cupertino-pane">
      <ion-list lines="full">
        <ion-item v-for="event in events" :key="event.id" button :detail="false" @click="switchEvent(event.id)">
          <ion-label>
            <ion-text>
              <h2>{{ event.title }}</h2>
            </ion-text>

            <ion-text color="moto">
              <span class="text-sm block">{{ event.held_at|formatDateTime }}</span>
            </ion-text>

            <ion-text>
              <span class="text-sm block">{{ event.total_checkins }} / {{ event.total_capacity }} {{ $t('capacitycheckinlabel') }}</span>
            </ion-text>
          </ion-label>
          <ion-icon name="checkmark" v-if="selectedEventId === event.id" slot="end" />
        </ion-item>
      </ion-list>
    </div>
  </ion-page>
</template>

<script>
    import CheckInModal from '@/components/modals/CheckInModal';
    import { QrcodeStream } from '@motoinside/vue-qrcode-reader';
    import { Capacitor } from '@capacitor/core';
    import { CHECKED_IN_RIDERS, GET_TRAINING_RIDER, GET_TRAINING_EVENTS } from '../graphql/queries';
    import { CupertinoPane } from 'cupertino-pane';
    import { SET_EVENT_ID_MUTATION } from '@/store/store-mutations';
    import { mapGetters } from "vuex";
    import { GET_EVENT_ID_GETTER } from "@/store/store-getters";
    import { captureException } from "@/utils";
    import { LAST_USED_SCANNER_CAMERA } from "@/configs";
    import { Preferences } from '@capacitor/preferences';
    import { KeepAwake } from '@capacitor-community/keep-awake';

    const iosRTC = window?.cordova?.plugins?.iosrtc;

    export default {
      components: {
        QrcodeStream,
      },
      data() {
        return {
          isStreamApiSupported: false,
          devices: [],
          scannedValue: '',
          totalCheckin: 0,
          hasStartUpError: false,
          hasError: false,
          isIos: false,
          camera: 'off',
          selectedCamera: 'off',
          cupertinoPane: null,
          enableTimer: null,
        }
      },
      computed: {
        ...mapGetters({selectedEventId: GET_EVENT_ID_GETTER}),
      },
      async mounted() {
        this.keepScreenAwake();
        await this.detectCameras();

        this.selectCamera(await this.getPreferredCamera());

        this.cupertinoPane = new CupertinoPane(
        '.cupertino-pane', // Pane container selector
        {
          parentElement: 'body',
          backdrop: true,
          buttonDestroy: false,
          freeMode: true,
          bottomClose: true,
          onBackdropTap: () => {
            this.cupertinoPane.destroy({ animate: true });
          },
          breaks: {
            bottom: {
              height: 20,
              enabled: true,
            }
          }
        });
      },
      beforeCreate() {
        if(Capacitor.getPlatform() === 'ios') {
          document.body.style.backgroundColor = 'transparent';
        }
      },
      apollo: {
        getCheckedInRiders: {
          query: CHECKED_IN_RIDERS,
          fetchPolicy: 'network-only',
          variables() {
            return {
              event_id: this.selectedEventId
            }
          },
          error(error) {
            this.error = error.message
          }
        },
        events:  {
          query: GET_TRAINING_EVENTS,
          update: data => data.getTrainingEvents,
          fetchPolicy: 'network-only',
        },
      },
      beforeDestroy() {
        if (this.enableTimer) {
          clearTimeout(this.enableTimer);
        }
      },
      destroy() {
        this.cupertinoPane.destroy();
        this.screenSleep();
      },
      methods: {
        async keepAwakeSupported() {
          const result = await KeepAwake.isSupported();

          return result.isSupported && Capacitor.isNativePlatform();
        },
        async screenSleep() {
          if (await this.keepAwakeSupported()) {
            KeepAwake.allowSleep();
          }
        },
        async keepScreenAwake() {
          if (await this.keepAwakeSupported()) {
            KeepAwake.keepAwake();
          }
        },
        async getDevices() {
          if (Capacitor.getPlatform() === 'ios') {
            return await iosRTC?.enumerateDevices();
          }

          return await navigator.mediaDevices.enumerateDevices();
        },
        async detectCameras() {
          // Filter some devices, known to be bad choices.
          const deviceBlackList = ["OBS Virtual Camera", "OBS-Camera"];

          if (!navigator?.mediaDevices?.getUserMedia && !iosRTC) {
            return;
          }

          this.isStreamApiSupported = true;

          this.devices = (await this.getDevices())
            .filter(({ kind }) => kind === "videoinput")
            .filter(({ label }) => !deviceBlackList.includes(label))
            .filter(({ label }) => !label.includes("infrared"));
        },
        selectCamera(deviceId) {
          this.camera = this.selectedCamera = deviceId;
        },
        switchEvent(eventId) {
          this.$store.commit(SET_EVENT_ID_MUTATION, eventId);
          this.cupertinoPane.destroy({ animate: true });
        },
        async findRiderAndShowModal(text) {
          if(!text) {
            return;
          }

          const { data, errors } = await this.$apollo.query({
              query: GET_TRAINING_RIDER,
              variables: {
                event_id: this.selectedEventId,
                qrcode: text
              },
              fetchPolicy: 'network-only',
              errorPolicy: 'all',
            });

          if(errors && errors.length) {
            const toast = await this.$ionic.toastController.create({
              color: 'danger',
              duration: 6000,
              message: errors[0].message,
            });

            this.hasError = true;
            setTimeout(() => {
              this.hasError = false;
            }, 1000)

            this.reEnableCamera(6000);
            await toast.present();
          } else {
              this.showModal(data.getTrainingCheckIn);
          }
        },
        displayContentsWeb(text){
          this.camera = 'off';
          if (!text) {
            this.reEnableCamera(6000);
            return;
          }
          this.findRiderAndShowModal(text);
        },
        savePreferredCamera(id) {
          // Do not save 'off' as a preferred camera
          if (id === 'off') {
            return;
          }
          Preferences.set({key: LAST_USED_SCANNER_CAMERA, value: id});
        },
        async getPreferredCamera() {
          const preferredCamera = await Preferences.get({key: LAST_USED_SCANNER_CAMERA});

          return preferredCamera?.value || 'auto';
        },
        onInit(p) {
          p.then( () => {
            this.savePreferredCamera(this.selectedCamera);
          })
          p.catch( (e) => {
            this.savePreferredCamera('auto');
            captureException(e);
            this.hasStartUpError = true;
          });
        },
        reEnableCamera(timeout) {
          if (this.enableTimer) {
            clearTimeout(this.enableTimer);
          }

          this.enableTimer = setTimeout(() => {
            this.camera = this.selectedCamera;
            this.enableTimer = null;
          }, timeout);
        },
        openSwitchPane() {
          this.cupertinoPane.present({animate: true});
        },
        showModal(trainingCheckIn) {
          this.$ionic.modalController
            .create({
              component: CheckInModal,
              componentProps: {
                parent: this,
                propsData: {
                  checkin: trainingCheckIn,
                  closeMe: () => {
                    this.$ionic.modalController.dismiss();
                    this.reEnableCamera(100);
                  }
                },
              },
            })
            .then(m => m.present())
        }
      }
    }
</script>

<style scoped>
  .title-ios.title-small {
    padding: 0;
  }

  .small-toolbar {
    --min-height: 2rem;
  }

  .overlay-error {
    background-color: rgba(255, 0, 0, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }

  .bounce-enter-active {
  animation: bounce-in .4s;
}
.bounce-leave-active {
  animation: bounce-in .4s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

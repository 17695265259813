<template>
  <base-modal :title="$t('checkin')" @close="close">
    <ion-content>
      <div v-if="checkin">
        <PageItem>
          <ion-grid>
            <ion-row>
              <ion-col size="3" class="flex justify-center">
                <ion-avatar>
                  <img :src="`${$apiStorageUrl}/${checkin.user.profile_image}`">
                </ion-avatar>
              </ion-col>
              <ion-col>
                <ion-text>
                  <h1 class="text-xl">
                    {{ checkin.user.first_name }} {{ checkin.user.last_name }}
                  </h1>
                </ion-text>

                <ion-text>
                  <h2>{{ dateOfBirth }} ({{ $t('yearsold', { age }) }})</h2>
                </ion-text>
                <ion-text>
                  <span>{{ commaSeperatedSports }}</span>
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </PageItem>


        <ion-list v-if="checkin.orders && checkin.orders.length" lines="full">
          <template v-for="eventGroup in eventGroups">
            <div :key="eventGroup.event.id">
              <ion-item-divider color="dark">
                <ion-text color="moto"><strong>{{ $t('event') }}: {{ eventGroup.event.title }}</strong></ion-text>
                <ion-text slot="end" class="mr-4">{{ eventGroup.event.held_at | formatDateTime }}</ion-text>
              </ion-item-divider>
              <ion-item-divider v-show="Object.keys(eventGroup.groupedTickets).length">
                <ion-label color="moto">{{ $t('tickets') }}</ion-label>
              </ion-item-divider>
              <ion-item v-for="(group, idx) in eventGroup.groupedTickets" :key="idx">
                <ion-label :color="group.is_refunded ? 'danger' : null">{{ group.items.length }}x {{ group.name }}</ion-label>
                <ion-label :color="group.is_refunded ? 'danger' : null" slot="end" class="text-right">{{ group.items[0].price | priceAmount }}</ion-label>
              </ion-item>

              <ion-item-divider v-show="Object.keys(eventGroup.groupedProducts).length">
                <ion-label color="moto">{{ $t('products') }}</ion-label>
              </ion-item-divider>
              <ion-item v-for="(group, idx) in eventGroup.groupedProducts" :key="idx">
                <ion-label :color="group.is_refunded ? 'danger' : null">{{ group.items.length }}x {{ group.name }}</ion-label>
                <ion-label :color="group.is_refunded ? 'danger' : null" slot="end" class="text-right">{{ group.items[0].productVariant.price | priceAmount }}</ion-label>
              </ion-item>
            </div>
          </template>
        </ion-list>

        <PageItem>
          <ion-grid>

            <ion-row v-if="!checkin.training_ticket">
              <ion-col>
                <Error :error="$t('userhasnotpaid')" />
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-text v-if="checkin.training_ticket">
                  <ion-icon name="checkmark-circle" color="moto" /> {{ $t('userhaspaid') }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </PageItem>
      </div>
    </ion-content>

    <ion-footer>
      <LoadingButton :disabled="loading" :loading="loading" @click="checkInRider">
        {{ $t('checkin') }}
      </LoadingButton>
    </ion-footer>
  </base-modal>
</template>

<script>
  import * as moment from 'moment';
  import PageItem from '@/components/PageItem'
  import {CHECKIN_RIDER_TO_EVENT} from "@/graphql/mutations";
  import BaseModal from './BaseModal.vue';
  import { groupOrderablesByEvent } from "@/utils";

  export default {
    components: {
      PageItem,
      BaseModal
    },
    props: ['closeMe', 'checkin'],
    data() {
      return {
        loading: false,
        sports: {
          CROSS: {
            name: "Cross"
          },
          SIDECAR: {
            name: "Zijspan"
          },
          TRIAL: {
            name: "Trial"
          },
          ENDURANCE: {
            name: "Enduro"
          },
          SUPERMOTO: {
            name: "Supermoto"
          },
          GRASS: {
            name: "Grasbaan"
          },
          QUADS: {
            name: "Quads"
          }
        }
      }
    },
    computed: {
      commaSeperatedSports() {
         let arraySports = [];

         this.checkin.user.sports.forEach(sport => {
           arraySports.push(this.sports[sport.name].name);
         })

        return arraySports.join(', ')
      },
      dateOfBirth() {
        return moment(this.checkin.user.date_of_birth).format('DD-MM-YYYY')
      },
      age() {
        return moment().diff(moment(this.checkin.user.date_of_birth, 'YYYY-MM-DD'), 'years');
      },
      eventGroups() {
        return groupOrderablesByEvent(this.checkin.orders);
      }
    },
    methods: {
      async checkInRider() {
        this.loading = true;

        try {
            const { errors } = await this.$apollo.mutate({
              mutation: CHECKIN_RIDER_TO_EVENT,
              variables: {
                user_id: this.checkin.user.id,
                event_id: this.$parent.selectedEventId
              },
              errorPolicy: 'all'
            })
            this.$parent.$apollo.queries.getCheckedInRiders.refetch();
            if(errors?.[0]?.message) {
              const errorToast = await this.$ionic.toastController.create({
                color: 'danger',
                duration: 3000,
                message: errors?.[0]?.message,
              })

              await errorToast.present();
            } else {
              this.showToast();
            }
            this.loading = false;
            this.close();
        } catch (error) {
          this.error = error
        }
      },
      async showToast() {
        const toast = await this.$ionic.toastController.create({
          color: 'success',
          duration: 3000,
          message: this.$i18n.t('ridercheckedin'),
        });

        await toast.present();
      },
      close() {
        if (this.closeMe) {
          this.closeMe();
        } else {
          this.$ionic.modalController.dismiss();
        }
      }
    }
  }
</script>
